import React from "react";

const Twitter = () => (
  <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.548 19.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 2.305a9.829 9.829 0 01-2.828.775A4.94 4.94 0 0023.337.357a9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67.898a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 6.855v.063a4.926 4.926 0 003.95 4.826 4.915 4.915 0 01-2.223.085 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 17.288a13.94 13.94 0 007.548 2.208"
      fill="currentColor"
    />
  </svg>
);

export default Twitter;
